import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "align-items-end flex-column" }
const _hoisted_7 = { class: "card-body py-3" }
const _hoisted_8 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "col" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "col" }
const _hoisted_15 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_16 = { class: "col" }
const _hoisted_17 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_18 = {
  name: "active",
  value: "ACT"
}
const _hoisted_19 = {
  name: "disabled",
  value: "DIS"
}
const _hoisted_20 = {
  name: "disabled",
  value: "INV"
}
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "table-responsive" }
const _hoisted_23 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_24 = { class: "fw-bolder text-muted" }
const _hoisted_25 = { class: "min-w-150px" }
const _hoisted_26 = { class: "min-w-120px" }
const _hoisted_27 = { class: "min-w-120px" }
const _hoisted_28 = { class: "min-w-120px" }
const _hoisted_29 = { class: "min-w-120px" }
const _hoisted_30 = { class: "min-w-120px" }
const _hoisted_31 = { class: "min-w-100px text-end" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_34 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_35 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_36 = { class: "badge-light badge text-primary" }
const _hoisted_37 = { class: "badge-light badge text-primary" }
const _hoisted_38 = {
  key: 0,
  class: "badge-light-success badge text-success"
}
const _hoisted_39 = {
  key: 1,
  class: "badge-light-primary badge text-primary"
}
const _hoisted_40 = { class: "text-end" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.usersList.title")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(this.$store.getters.currentSettings.clientName), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", {
          href: "#",
          class: "btn btn-primary btn-light",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resendUserInvitation()))
        }, "Relancer les invitations")
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.identity) = $event)),
              type: "text",
              name: "fname",
              class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
              placeholder: _ctx.$t('pages.users.name')
            }, null, 8, _hoisted_11), [
              [_vModelText, _ctx.filter.identity]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.email) = $event)),
              type: "text",
              name: "lname",
              class: "form-control form-control-lg form-control-solid",
              placeholder: _ctx.$t('pages.users.email')
            }, null, 8, _hoisted_13), [
              [_vModelText, _ctx.filter.email]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.user_type) = $event)),
              class: "form-control form-control-solid"
            }, [
              _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t("pages.users.userType")), 1),
              _cache[7] || (_cache[7] = _createElementVNode("option", {
                name: "pilote",
                value: "pilote"
              }, "Pilote", -1)),
              _cache[8] || (_cache[8] = _createElementVNode("option", {
                name: "network",
                value: "network"
              }, "Réseau baromètre", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("option", {
                name: "manager",
                value: "manager"
              }, "Manager", -1))
            ], 512), [
              [_vModelSelect, _ctx.filter.user_type]
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.status) = $event)),
              class: "form-control form-control-solid"
            }, [
              _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$t("pages.users.status")), 1),
              _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t("pages.users.active")), 1),
              _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$t("pages.users.disabled")), 1),
              _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$t("pages.users.invited")), 1)
            ], 512), [
              [_vModelSelect, _ctx.filter.status]
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary btn-light btn-active-light-primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchUsers()))
            }, _toDisplayString(_ctx.$t("general.search")), 1),
            _createElementVNode("button", {
              type: "submit",
              class: "ms-3 btn btn-icon btn-light btn-active-light-primary",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.resetFilter()))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "fas fa-redo fs-7 pt-1" }, null, -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("table", _hoisted_23, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_24, [
              _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t("pages.usersList.name")), 1),
              _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("pages.usersList.email")), 1),
              _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.$t("pages.usersList.accessLevel")), 1),
              _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.$t("pages.usersList.perimeter")), 1),
              _createElementVNode("th", _hoisted_29, _toDisplayString(_ctx.$t("pages.usersList.lastConnection")), 1),
              _createElementVNode("th", _hoisted_30, _toDisplayString(_ctx.$t("pages.usersList.status")), 1),
              _createElementVNode("th", _hoisted_31, _toDisplayString(_ctx.$t("pages.usersList.actions")), 1)
            ])
          ]),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_32, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.users, (user) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: user.id
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("a", _hoisted_33, _toDisplayString(user.userprofile.lastname), 1),
                      _createElementVNode("span", _hoisted_34, _toDisplayString(user.userprofile.firstname), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", _hoisted_35, _toDisplayString(user.email), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_36, _toDisplayString(user.userprofile.user_type.label), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_37, [
                        _cache[11] || (_cache[11] = _createElementVNode("i", { class: "fas fa-sitemap fs-5 me-2" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(user.userprofile.related_companies[0].name), 1)
                      ])
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("td", null, [
                      _createElementVNode("a", {
                        href: "#",
                        class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                      }, "11/02/2021"),
                      _createElementVNode("span", { class: "text-muted fw-bold text-muted d-block fs-7" }, "16h37")
                    ], -1)),
                    _createElementVNode("td", null, [
                      (user.status === 'ACT')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_38, "Actif"))
                        : _createCommentVNode("", true),
                      (user.status === 'INV')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_39, "Invité"))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_40, [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.editUser(user)),
                        href: "#",
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      }, _cache[12] || (_cache[12] = [
                        _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                          _createElementVNode("svg", {
                            fill: "none",
                            viewBox: "0 0 24 24",
                            height: "24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              opacity: "0.3",
                              d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                              fill: "black"
                            }),
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                              fill: "black"
                            })
                          ])
                        ], -1)
                      ]), 8, _hoisted_41),
                      _cache[13] || (_cache[13] = _createStaticVNode("<a href=\"#\" class=\"btn btn-icon btn-bg-light btn-active-color-primary btn-sm\"><span class=\"svg-icon svg-icon-3\"><svg fill=\"none\" viewBox=\"0 0 24 24\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path xmlns=\"http://www.w3.org/2000/svg\" d=\"M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z\" fill=\"black\"></path></svg></span></a>", 1))
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_42, _cache[15] || (_cache[15] = [
              _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
                _createElementVNode("span", {
                  class: "spinner-border text-primary",
                  role: "status"
                })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}